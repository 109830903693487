import React from "react";

import { images } from "../export";

const Mgrid = () => {
  return (
    <div className="columns-1 sm:columns-2 lg:columns-3 py-10 md:py-20 gap-4">
      <div className="mb-4 break-inside-avoid">
        <img
          src={images.gall2}
          alt="Gall 1"
          className="w-full object-cover rounded-lg"
        />
      </div>
      <div className="mb-4 break-inside-avoid">
        <img
          src={images.gall1}
          alt="Gall 1"
          className="w-full object-cover rounded-lg"
        />
      </div>
      <div className="mb-4 break-inside-avoid">
        <img
          src={images.gall6}
          alt="Gall 1"
          className="w-full object-cover rounded-lg"
        />
      </div>
      <div className="mb-4 break-inside-avoid">
        <img
          src={images.gall4}
          alt="Gall 1"
          className="w-full object-cover rounded-lg"
        />
      </div>
      <div className="mb-4 break-inside-avoid">
        <img
          src={images.gall5}
          alt="Gall 1"
          className="w-full object-cover rounded-lg"
        />
      </div>
      <div className="mb-4 break-inside-avoid">
        <img
          src={images.gall7}
          alt="Gall 1"
          className="w-full object-cover rounded-lg"
        />
      </div>
    </div>
  );
};

export default Mgrid;
