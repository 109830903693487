import React from "react";
import Hero from "../sections/Hero";
import About from "../sections/About";
import Services from "../sections/Services";
import Portfolio from "../sections/Portfolio";
import Working from "../sections/Working";
import OurTeam from "../sections/OurTeam";

const Home = () => {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <OurTeam />
      <Portfolio />
      <Working />
    </>
  );
};

export default Home;
