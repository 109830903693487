import React from "react";
import abt11 from "../assets/abt11.webp";
import abt2 from "../assets/abt2.jpg";
import abt1 from "../assets/abt1.jpg";
import abt3 from "../assets/abt3.jpg";

const AboutPage = () => {
  return (
    <div className="about__body">
      <div className="about__container">
        <div className="about__content__left">
          <div className="about__row">
            <div className="about__img__wrapper">
              <img src={abt11} alt="About Photos" />
            </div>
            <div className="about__img__wrapper">
              <img src={abt1} alt="About Photos" />
            </div>
            <div className="about__img__wrapper">
              <img src={abt2} alt="About Photos" />
            </div>
            <div className="about__img__wrapper">
              <img src={abt3} alt="About Photos" />
            </div>
          </div>
        </div>
        <div className="about__content__right">
          <div className="about__content">
            <h4 className="text-yellow-500">About Hamsa</h4>
            <h2>COMPANY's PROFILE</h2>
            <p>
              Hamsa Steel Industries, Ltd is a professional steel manufacturer
              engaged in the research, development, production, sale and
              service. Our company is located in Braeside, Victoria, Australia,
              which is Australia's largest steel processing center. In line with
              the development concept of "Quality conquer the world, service
              achievement future.” We Dedicate to strict quality control and
              thoughtful customer service, and our experienced staff members are
              always available to discuss your requirements and ensure full
              customer satisfaction. We have more than ten years of production,
              trade experience and rich high-end 200 series 300 series 400
              series steel. Our main products include various Stainless steel
              products ,including stainless steel plate,stainless steel round
              pipe,stainless steel square pipe, stainless steel coil,stainless
              steel bar, stainless steel profiles. We also supply PPGI,
              galvanized steel coil ,color coated aluminum coil, carbon steel
              coil ,steel round bar, steel flat bar,steel pipe ,steel angle bar
              ,H-beam ,I-beam, rebar etc. Main market distributes in North
              America, South America, Africa, Southeast Asia, Europe as well as
              Oceania. As a export window of Australian steel industry, Hamsa
              Steel has been conduction extensive and friendly cooperation with
              domestic and overseas company on the basis of diversified
              products, superior quality, reasonable price and thoughtful
              service.We have SGS, CE, ISO and other certificates, and accept
              factory tests. Welcome business partners to visit our company and
              discuss cooperation. We are looking forward to do business and
              making friends with clients all around the world.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
