import React from "react";
import { motion } from "framer-motion";
import { slideUpVariants, zoomInVariants } from "./animation";
import { planning } from "../export";

const Working = () => {
  return (
    <div id="services" className="w-full bg-white">
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg-w[80%] w-[90%] m-auto py-[60px] flex flex-col justify-between items-center gap-[20px]"
      >
        <motion.h1
          variants={slideUpVariants}
          className="text-yellow-500 uppercase text-2xl"
        >
          Information
        </motion.h1>
        <motion.h1
          variants={slideUpVariants}
          className="text-black uppercase text-[40px] font-bold text-center"
        >
          Tailored service
        </motion.h1>
        <motion.div
          variants={slideUpVariants}
          className="w-[120px] h-[6px] bg-yellow-500"
        ></motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={zoomInVariants}
          className="w-full grid lg:grid-cols-4 grid-cols-1 justify-center items-start gap-[20px] mt-[30px]"
        >
          {planning &&
            planning.map((item, index) => (
              <motion.div
                className="flex flex-col justify-center items-center gap-5 border-2 border-yellow-500 rounded-lg p-6"
                key={index}
              >
                <div className="">
                  <item.icon className="size-[80px] bg-yellow-500 hover:bg-black hover:fill-white p-4 rounded-full cursor-pointer" />
                </div>
                <h1 className="text-2xl font-bold uppercase text-[20px]">
                  {item.title}
                </h1>
                <p className="text-[20px text-center text-gray-600">
                  {item.about}
                </p>
              </motion.div>
            ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Working;
