import building from "../src/assets/building.svg";
import construction from "../src/assets/construction.svg";
import design from "../src/assets/design.svg";
import document from "../src/assets/document.svg";
import paint from "../src/assets/paint.svg";
import support from "../src/assets/support.svg";

import gall1 from "../src/assets/gall1.webp";
import gall2 from "../src/assets/gall2.webp";
import gall3 from "../src/assets/gall3.webp";
import gall4 from "../src/assets/gall4.png";
import gall5 from "../src/assets/gall5.jpg";
import gall6 from "../src/assets/gall6.jpg";
import gall7 from "../src/assets/gall7.jpg";
import gall8 from "../src/assets/gall8.webp";

import { IoDocumentTextSharp } from "react-icons/io5";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaRegBuilding } from "react-icons/fa";
import { FaSitemap } from "react-icons/fa";

export const images = {
  gall1,
  gall2,
  gall3,
  gall4,
  gall5,
  gall6,
  gall7,
  gall8,
};

export const allservices = [
  {
    icon: building,
    title: "Distribution",
    about:
      "We are distributors and stockists of Structural Steel,Universal Beams & Columns,Parallel Flange Channels and many more.",
  },
  {
    icon: construction,
    title: "Processing",
    about:
      "Our steel processing capabilities cover a wide range of products, including structural steel, flat bar, angle, tubular, and steel plate products.",
  },
  {
    icon: design,
    title: "DeliVery",
    about:
      "Extensive footprint of distribution sites in: Adelaide, Perth, Sydney, Kalgoorlie, Tasmania, and Melbourne.",
  },
  {
    icon: document,
    title: "Empowerment",
    about:
      "Our people are empowered to make decisions, which in turn provides our customers with agility, certainty and excellence in customer service. We are accessible to our customers, always.",
  },
  {
    icon: paint,
    title: "Integrity",
    about:
      "With all our decisions and actions our team upholds the highest standards of integrity. We are accountable for delivering on our customer commitments.",
  },
  {
    icon: support,
    title: "Responsiveness",
    about:
      "We understand what matters to our customers, and we act on it,because delivering on our commitment is fundamental to who we are as a leading independent steel distributor. ",
  },
];

export const planning = [
  {
    icon: IoDocumentTextSharp,
    title: "Production",
    about:
      "Hamsa Steel Industries, Ltd. has all the appropriate equipment to manufacture long products ranging from unalloyed, low-alloy to high-alloy special steels.",
  },
  {
    icon: MdOutlineDesignServices,
    title: "Certifications",
    about:
      "We are committed to all relevant standards and regulations and have integrated a process-oriented management system into our corporate culture.",
  },
  {
    icon: FaRegBuilding,
    title: "Surcharges",
    about:
      "Here you will find the current alloy and scrap surcharges as well as our energy surcharges. We will provide you with future surcharges three days before the end of the month.",
  },
  {
    icon: FaSitemap,
    title: "Download-Center",
    about:
      "Here, you will find product-related information about the respective materials in the form of brochures, data sheet collections, or material diagrams.",
  },
];
