import React from "react";
import { motion } from "framer-motion";
import { slideUpVariants, zoomInVariants } from "./animation";

const About = () => {
  return (
    <div
      className="lg:w-[80%] w-[90%] m-auto py-[60px] flex lg:flex-row flex-col justify-between items-start gap-[50px]"
      id="about"
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg:w-[60%] w-full flex flex-col justify-center items-start gap-6"
      >
        <motion.h1
          variants={slideUpVariants}
          className="text-yellow-500 text-2xl uppercase"
        >
          About Us
        </motion.h1>
        <motion.h1
          variants={slideUpVariants}
          className="text-white uppercase text-[40px] font-bold"
        >
          Company's Profile
        </motion.h1>
        <div className="w-[120px] h-[6px] bg-yellow-500"></div>
        <p className="text-3xl italic text-gray-50 mt-[60px]">
          Hamsa Steel Industries, Ltd., Ltd. was established in the year 2000
          and is situated in Braeside, Victoria, Australia. For over two
          decades, we have been involved in the production, processing, and
          sales of a variety of steel products.
        </p>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg:w-[40%] w-full flex flex-col justify-center items-start gap-6"
      >
        <p className="text-white text-lg text-justify">
          Our primary offerings consist of stainless steel plates, pipes, bars,
          coils, fittings, and other carbon steel items. The company specializes
          in the manufacture and sale of hot-dipped galvanized steel
          sheets/coils (with an annual output of 150,000 tons), corrugated steel
          sheets (with an annual output of 80,000 tons), and highway guardrails
          (with an annual output of 400,000 tons). Our total annual sales amount
          to approximately 1.60 billion Australian Dollars, with a profit tax of
          around 120 Australian Dollars.
        </p>
        <motion.button
          variants={zoomInVariants}
          className="bg-yellow-500 hover:bg-white hover:text-black px-10 py-3 rounded-lg text-black font-bold"
        >
          READ MORE
        </motion.button>
      </motion.div>
    </div>
  );
};

export default About;
