// import React, { useRef } from "react";
// import emailjs from "@emailjs/browser";
// import styled from "styled-components";

// const StyledContactForm = styled.div`
//   width: 400px;
//   height: 700px;
//   margin-top: 4rem;

//   h1 {
//     text-align: center;
//     font-size: 3rem;
//     color: #fff;
//     text-transform: uppercase;
//   }

//   form {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     width: 100%;
//     font-size: 16px;
//     color: black;

//     input {
//       width: 100%;
//       height: 35px;
//       padding: 7px;
//       outline: none;
//       border-radius: 5px;
//       border: 1px solid rgb(220, 220, 220);

//       &:focus {
//         border: 2px solid rgba(0, 206, 158, 1);
//       }
//     }

//     textarea {
//       max-width: 100%;
//       min-width: 100%;
//       width: 100%;
//       max-height: 100px;
//       min-height: 100px;
//       padding: 7px;
//       outline: none;
//       border-radius: 5px;
//       border: 1px solid rgb(220, 220, 220);

//       &:focus {
//         border: 2px solid rgba(0, 206, 158, 1);
//       }
//     }

//     label {
//       margin-top: 1rem;
//       margin-bottom: 0.4rem;
//       text-transform: uppercase;
//       color: rgb(234, 179, 8);
//     }

//     input[type="submit"] {
//       margin-top: 2rem;
//       cursor: pointer;
//       background: rgb(234, 179, 8);
//       color: #000;
//       border: none;
//     }
//   }
// `;

const Contact = () => {
  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs
  //     .sendForm("service_h440pva", "template_b5biyoc", form.current, {
  //       publicKey: "R6XFCADUvYM6prngL",
  //     })
  //     .then(
  //       () => {
  //         console.log("SUCCESS!");
  //       },
  //       (error) => {
  //         console.log("FAILED...", error.text);
  //       }
  //     );
  // };

  return (
    <div className="hola">
      {/* <StyledContactForm>
        <h1>Contact Us</h1>
        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Message</label>
          <textarea name="message" />
          <input type="submit" value="Send" />
        </form>
      </StyledContactForm> */}
      Contact
    </div>
  );
};

export default Contact;
