import React from "react";
import { NavLink } from "react-router-dom";
import { FaXmark, FaBars } from "react-icons/fa6";
import { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function closeMenu() {
    setIsMenuOpen(false);
  }

  const navItems = [
    {
      link: "Home",
      path: "/",
    },
    {
      link: "About",
      path: "about",
    },
    {
      link: "Services",
      path: "services",
    },
    {
      link: "Gallery",
      path: "gallery",
    },
    {
      link: "Contact",
      path: "contact",
    },
  ];
  return (
    <>
      <div className="navbar__one navbar__flex sticky">
        <div className="navbar__one__left navbar__flex">
          <div className="navbar--one--email">
            <FaEnvelope />
            <span>luca@hamsasteelindustries.com</span>
          </div>
        </div>
        <div className=" navbar__one__flex">
          <span>
            <MdLocationOn />
          </span>
          <p>64 Mills Rd Braeside VIC 3195</p>
        </div>
      </div>
      <nav
        id="navBar"
        className="w-full flex bg-white justify-between items-center gap-1 lg:px-16 px-6 py-4 sticky top-0 z-50"
      >
        <h1 className="text-black md:text-4xl text-3xl font-bold font-rubik">
          Hamsa <span className="text-yellow-500 italic">Industries</span>
        </h1>
        {/* <img src={logo} alt="Logo" className="" /> */}
        <ul className="lg:flex justify-center items-center gap-6 hidden">
          {navItems &&
            navItems.map(({ link, path }) => (
              <NavLink
                key={path}
                className="text-black uppercase font-bold cursor-pointer p-3 rounded-full hover:bg-yellow-500 hover:text-black text-[15px]"
                to={path}
              >
                {link}
              </NavLink>
            ))}
        </ul>
        <button className="bg-yellow-500 hover:bg-black hover:text-white text-black px-10 py-3 rounded-full font-semibold transform hover:scale-105 transition-transform duration-300 cursor-pointer md:flex hidden">
          GET A QUOTE
        </button>

        <div
          className="flex justify-between items-center lg:hidden mt-3"
          onClick={toggleMenu}
        >
          <div>
            {isMenuOpen ? (
              <FaXmark className="text-yellow-500 text-3xl cursor-pointer" />
            ) : (
              <FaBars className="text-yellow-500 text-3xl cursor-pointer" />
            )}
          </div>
        </div>
        <div
          className={`${
            isMenuOpen ? "flex" : "hidden"
          } w-full h-fit bg-yellow-500 p-4 absolute top-[72px] left-0 lg:hidden `}
          onClick={closeMenu}
        >
          <ul className="flex flex-col justify-center items-center gap-2 w-full">
            {navItems &&
              navItems.map(({ link, path }) => (
                <NavLink
                  key={path}
                  className="text-black uppercase font-semibold cursor-pointer p-2 rounded-lg hover:bg-black hover:text-white w-full text-center"
                  to={path}
                >
                  {link}
                </NavLink>
              ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
