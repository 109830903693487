import React from "react";
import "../Components/services.scss";

const ServicesPage = () => {
  return (
    <section className="min-h-screen bg-gray-900 text-center py-20 px-8 xl:px-0 flex flex-col justify-center">
      <span className="text-gray-400 text-lg max-w-lg mx-auto mb-2 capitalize flex items-center">
        What We Offer
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="text-indigo-600 ml-3 w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
          />
        </svg>
      </span>
      <h1 className="text-white text-4xl md:text-5xl xl:text-6xl font-semibold max-w-3xl mx-auto mb-16 leading-snug">
        Services Built Specifically for your Business
      </h1>
      <div className="grid-offer text-left grid sm:grid-cols-2 md:grid-cols-2 gap-5 max-w-5xl mx-auto">
        <div className="card bg-gray-800 p-10 relative">
          <div className="circle"></div>
          <div className="relative lg:pr-52">
            <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
              Cambering <br /> Machine
            </h2>
            <p className="text-gray-400">
              Cambering, a process that involves bending steel beams to a
              specific curvature, is a critical step in many construction and
              architectural applications.
            </p>
          </div>
          <div className="icon"></div>
        </div>
        <div className="card bg-gray-800 p-10 relative">
          <div className="circle"></div>
          <div className="relative lg:pl-48">
            <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
              Steel Sheet
              <br /> Processing
            </h2>
            <p className="text-gray-400">
              Hamsa Steel’s services extends to steel sheet processing, to
              provide a range of steel sheet products in sizes suitable for use
              in many manufacturing processes, such as turret punching, brake
              press and laser.
            </p>
          </div>
        </div>
        <div className="card bg-gray-800 p-10 relative">
          <div className="circle"></div>
          <div className="relative lg:pr-44">
            <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
              Oxy Profile <br />
              Cutters
            </h2>
            <p className="text-gray-400">
              Oxy profile cutters provide precision cutting with exceptional
              edge quality. This technology enables United Steel to craft
              intricate profiles and shapes with utmost accuracy.
            </p>
          </div>
        </div>
        <div className="card bg-gray-800 p-10 relative">
          <div className="circle"></div>
          <div className="relative lg:pl-48">
            <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
              Steel Processing
              <br /> Capabilities
            </h2>
            <p className="text-gray-400">
              At Hamsa Steel, we are able to provide processing services for a
              diverse range of steel products. Whether it’s structural steel,
              merchant bars, angles, tubular products, or steel plates.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesPage;
