import React from "react";
import { motion } from "framer-motion";
import { slideUpVariants, zoomInVariants } from "./animation";
import { allservices } from "../export";

const OurTeam = () => {
  return (
    <div id="services" className="w-full">
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={slideUpVariants}
        className="lg-w[80%] w-[90%] m-auto py-[60px] flex flex-col justify-between items-center gap-[20px]"
      >
        <motion.h1
          variants={slideUpVariants}
          className="text-yellow-500 uppercase text-2xl"
        >
          MEET THE TEAM
        </motion.h1>
        <motion.h1
          variants={slideUpVariants}
          className="text-white uppercase text-[40px] font-bold text-center"
        >
          Our professionals
        </motion.h1>
        <motion.div
          variants={slideUpVariants}
          className="w-[120px] h-[6px] bg-yellow-500"
        ></motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          variants={zoomInVariants}
        >
          <section className="py-6 dark:bg-gray-100 dark:text-gray-800">
            <div className="container flex flex-col items-center justify-center p-4 mx-auto sm:p-10">
              <div className="flex flex-row flex-wrap-reverse justify-center mt-8">
                <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 dark:bg-gray-800 dark:text-gray-100">
                  <img
                    alt=""
                    className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full dark:bg-gray-500"
                    src="https://www.bluescope.com/content/dam/bluescope/corporate/bluescope-com/our-company/assets/leadership_image6.png"
                  />
                  <div className="flex-1 my-4">
                    <p className="text-xl font-semibold leading-snug">
                      Kathleen Conlon
                    </p>
                    <p>Chief Strategy & Transformation Officer</p>
                  </div>
                  <div className="flex items-center justify-center p-3 space-x-3 border-t-2"></div>
                </div>
                <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 dark:bg-gray-800 dark:text-gray-100">
                  <img
                    alt=""
                    className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full dark:bg-gray-500"
                    src="https://www.bluescope.com/content/dam/bluescope/corporate/bluescope-com/our-company/assets/elt-board/Bluescope_April23_John%20Nowlan.jpg"
                  />
                  <div className="flex-1 my-4">
                    <p className="text-xl font-semibold leading-snug">
                      John Nowlan
                    </p>
                    <p>Chief Financial Officer</p>
                  </div>
                  <div className="flex items-center justify-center p-3 space-x-3 border-t-2"></div>
                </div>
                <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 dark:bg-gray-800 dark:text-gray-100">
                  <img
                    alt=""
                    className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full dark:bg-gray-500"
                    src="https://www.bluescope.com/content/dam/bluescope/corporate/bluescope-com/our-company/assets/elt-board/Bluescope_April23_KristieKeast.jpg"
                  />
                  <div className="flex-1 my-4">
                    <p className="text-xl font-semibold leading-snug">
                      Tania Archibald
                    </p>
                    <p> Steel Products Manager</p>
                  </div>
                  <div className="flex items-center justify-center p-3 space-x-3 border-t-2"></div>
                </div>
                <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 dark:bg-gray-800 dark:text-gray-100">
                  <img
                    alt=""
                    className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full dark:bg-gray-500"
                    src="https://www.bluescope.com/content/dam/bluescope/corporate/bluescope-com/our-company/assets/leadership_image2.png"
                  />
                  <div className="flex-1 my-4">
                    <p className="text-xl font-semibold leading-snug">
                      Mark Vassella
                    </p>
                    <p>Managing Director & CEO</p>
                  </div>
                  <div className="flex items-center justify-center p-3 space-x-3 border-t-2"></div>
                </div>
                <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 dark:bg-gray-800 dark:text-gray-100">
                  <img
                    alt=""
                    className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full dark:bg-gray-500"
                    src="https://www.bluescope.com/content/dam/bluescope/corporate/bluescope-com/our-company/assets/David%20Fallu.jpg"
                  />
                  <div className="flex-1 my-4">
                    <p className="text-xl font-semibold leading-snug">
                      Luca Theodore
                    </p>
                    <p>HR Manager</p>
                  </div>
                  <div className="flex items-center justify-center p-3 space-x-3 border-t-2"></div>
                </div>
                <div className="flex flex-col justify-center w-full px-8 mx-6 my-12 text-center rounded-md md:w-96 lg:w-80 xl:w-64 dark:bg-gray-800 dark:text-gray-100">
                  <img
                    alt=""
                    className="self-center flex-shrink-0 w-24 h-24 -mt-12 bg-center bg-cover rounded-full dark:bg-gray-500"
                    src="https://www.bluescope.com/content/dam/bluescope/corporate/bluescope-com/our-company/assets/Bluescope_ELT_Gaurav_Patel.jpg"
                  />
                  <div className="flex-1 my-4">
                    <p className="text-xl font-semibold leading-snug">
                      Gaurav Patel
                    </p>
                    <p>Interim Chief Legal Officer</p>
                  </div>
                  <div className="flex items-center justify-center p-3 space-x-3 border-t-2"></div>
                </div>
              </div>
            </div>
          </section>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default OurTeam;
