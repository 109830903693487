import React from "react";
import Mgrid from "../sections/Mgrid";

const Gallery = () => {
  return (
    <div className="scroll-smooth overflow-hidden bg-white">
      <div className="w-full md:pt-10 md:pb-20 px-4 md:px-16 justify-between md:bg-fixed bg-gradient-to-t">
        <div className="flex flex-col md:flex-row gap-5 md:gap-10 mt-20 md:pt-[7rem]">
          <h1 className="text-gray-800 font-medium md:w-1/2 tracking-tight text-3xl md:texr-4xl text-center">
            Check Out Our Product Gallery
          </h1>
          <p className="md:w-1/2 text-center">Kindly patronize us</p>
        </div>
        <Mgrid />
      </div>
    </div>
  );
};

export default Gallery;
